import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  DirectionsRenderer,
} from "@react-google-maps/api";

const GoogleMapCom = ({
  pickupLatLong,
  destinationLatLong,
  setPickupLatLong,
  setDestinationLatLong,
  setPickupPlaceName, // Add a prop to store pickup place name
  setDestinationPlaceName, // Add a prop to store destination place name
}) => {
  const [map, setMap] = useState(null);
  const [directions, setDirections] = useState(null);
  const [currentLocation, setCurrentLocation] = useState({
    lat: 40.697215,
    lng: -73.81376,
  });
  const [pickupLocation, setPickupLocation] = useState(null);
  const [destinationLocation, setDestinationLocation] = useState(null);
  const [geocoder, setGeocoder] = useState(null); // Store Geocoder instance

  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  // Parse the pickup and destination LatLong strings into objects
  const parseLatLong = (latLongStr) => {
    if (!latLongStr) {
      return;
    }
    const latlong = latLongStr?.split(",").map(Number);
    return { lat: latlong[0], lng: latlong[1] };
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB0V1g5YyGB_NE1Lw1QitZZGECA5-1Xnng", // Replace with your API key
  });

  // Function to reverse geocode and get place name
  const getPlaceName = (lat, lng, setPlaceName) => {
    if (geocoder) {
      const location = { lat, lng };
      geocoder.geocode({ location }, (results, status) => {
        if (status === "OK" && results[0]) {
          const placeName = results[0].formatted_address; // Get the formatted address
          setPlaceName(placeName); // Set place name in parent component
        } else {
          console.error(
            "Geocode was not successful for the following reason: " + status
          );
        }
      });
    }
  };

  const calculateDirections = () => {
    if (isLoaded && pickupLocation && destinationLocation) {
      const directionsService = new window.google.maps.DirectionsService();

      const request = {
        origin: pickupLocation,
        destination: destinationLocation,
        travelMode: window.google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirections(result);
        } else {
          console.error(`Error fetching directions: ${result}`);
        }
      });
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Error getting current location: ", error);
          setCurrentLocation({ lat: 40.697215, lng: -73.81376 });
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      alert("Geolocation is not supported by your browser.");
    }
  };

  // Update pickup and destination locations after parsing
  useEffect(() => {
    setPickupLocation(parseLatLong(pickupLatLong));
    setDestinationLocation(parseLatLong(destinationLatLong));
  }, [pickupLatLong, destinationLatLong]);

  useEffect(() => {
    if (isLoaded) {
      // Initialize Geocoder once map is loaded
      setGeocoder(new window.google.maps.Geocoder());
    }
    getCurrentLocation(); // Get current location when component mounts
  }, [isLoaded]);

  useEffect(() => {
    calculateDirections();
  }, [isLoaded, pickupLocation, destinationLocation]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={pickupLocation ?? currentLocation} // Center on current location if available
      zoom={ 17}
      onLoad={(map) => setMap(map)}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
      }}
    >
      {/* Draggable pickup marker */}
      {pickupLocation && (
        <MarkerF
          position={pickupLocation}
          draggable={true}
          onDragEnd={(e) => {
            const newPickupLocation = {
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            };
            setPickupLocation(newPickupLocation);
            setPickupLatLong(
              `${newPickupLocation.lat},${newPickupLocation.lng}`
            ); // Update the parent state
            getPlaceName(
              newPickupLocation.lat,
              newPickupLocation.lng,
              setPickupPlaceName
            ); // Fetch and update place name
          }}
          label="P" // Pickup marker label
        />
      )}

      {/* Draggable destination marker */}
      {destinationLocation && (
        <MarkerF
          position={destinationLocation}
          draggable={true}
          onDragEnd={(e) => {
            const newDestinationLocation = {
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            };
            setDestinationLocation(newDestinationLocation);
            setDestinationLatLong(
              `${newDestinationLocation.lat},${newDestinationLocation.lng}`
            ); // Update the parent state
            getPlaceName(
              newDestinationLocation.lat,
              newDestinationLocation.lng,
              setDestinationPlaceName
            ); // Fetch and update place name
          }}
          label="D" // Destination marker label
        />
      )}

      {currentLocation && !directions && (
        <MarkerF
          position={currentLocation}
          icon={{
            url: "assets/img/location-pin.png", // Replace with your own icon URL
            scaledSize: new window.google.maps.Size(20, 30),
          }}
        />
      )}

      {/* Render directions on map if available */}
      {directions && (
        <DirectionsRenderer
          directions={directions}
          options={{ suppressMarkers: true }}
        />
      )}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default GoogleMapCom;
