import { createSlice } from "@reduxjs/toolkit";
import {
  fetchCarList,
  fetchLatLong,
  fetchLocationList,
  fetchNotification,
  selectCar,
  selectLocation,
} from "../action/bookingAction";

const initialState = {
  Loading: false,
  carList: [],
  notifications: [],
  locationList: [],
};

export const bookingSlice = createSlice({
  name: "Trips",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    // select-location
    builder.addCase(selectLocation.pending, (state, action) => {
      state.Loading = true;
    });
    builder.addCase(selectLocation.fulfilled, (state, action) => {
      state.Loading = false;
    });
    builder.addCase(selectLocation.rejected, (state, action) => {
      state.Loading = false;
    });

    // fetch-car-list
    builder.addCase(fetchCarList.pending, (state, action) => {
      state.Loading = true;
    });
    builder.addCase(fetchCarList.fulfilled, (state, action) => {
      const { data, success } = action?.payload ?? {};
      state.carList = data?.length > 0 ? data : [];
      state.Loading = false;
    });
    builder.addCase(fetchCarList.rejected, (state, action) => {
      state.Loading = false;
    });

    // fetch-locations-list
    builder.addCase(fetchLocationList.pending, (state, action) => {});
    builder.addCase(fetchLocationList.fulfilled, (state, action) => {
      const { data, success } = action?.payload ?? {};
      state.locationList = data;
    });
    builder.addCase(fetchLocationList.rejected, (state, action) => {});
    // fetch-lat-long
    builder.addCase(fetchLatLong.pending, (state, action) => {});
    builder.addCase(fetchLatLong.fulfilled, (state, action) => {});
    builder.addCase(fetchLatLong.rejected, (state, action) => {});

    // select-car
    builder.addCase(selectCar.pending, (state, action) => {
      state.Loading = true;
    });
    builder.addCase(selectCar.fulfilled, (state, action) => {
      state.Loading = false;
    });
    builder.addCase(selectCar.rejected, (state, action) => {
      state.Loading = false;
    });

    // fetch-notification
    builder.addCase(fetchNotification.pending, (state, action) => {
      state.Loading = true;
    });
    builder.addCase(fetchNotification.fulfilled, (state, action) => {
      const { data, success } = action?.payload ?? {};
      state.notifications = data;
      state.Loading = false;
    });
    builder.addCase(fetchNotification.rejected, (state, action) => {
      state.Loading = false;
    });
  },
});

export const { toggleChange } = bookingSlice.actions;
export default bookingSlice.reducer;
