// export const BASE_URL = "http://192.168.1.15:4006/api/"; // live URL
// export const BASE_URL_Payment = "http://192.168.1.15:4006/square/"; // live URL

export const BASE_URL = "https://evo-go.com:4000/api/"; // live URL
export const BASE_URL_Payment = "https://evo-go.com:4000/square/"; // live URL

// // Production credential
// export const APPLICATION_ID = "sq0idp-Hxs1BPK4Aw4tm1H3bowwfg";
// export const LOCATION_ID = "LNHHK7JFR1GMG";


// sendbox credential
export const APPLICATION_ID = "sandbox-sq0idb-XdaLcIUOe-BzLt7cEjp4aA";
export const LOCATION_ID = "LNHHK7JFR1GMG";


// Auth :
export const loginAPI = "login";
export const signupAPI = "register";
export const forgotPasswordAPI = "forgot-password";
export const changePasswordAPI = "change-password";
export const continueAsGuestAPI = "guest-register";

// Profile :
export const profileAPI = "profile";
export const profileUpdateAPI = "profile/update";

// Cards :
export const addCardAPI = "add-card";
export const getCardAPI = "get-cards";
export const deleteCardAPI = "delete-card";
export const updateCardAPI = "update-card";

// Trip :
export const getTripHistoryAPI = "get-ride-history";
export const getRideDetailByIdAPI = "get-ride-details";
export const cancelRideAPI = "cancel-ride";

// Book :
export const selectLocationAPI = "select-location";
export const getCarsAPI = "get-cars-for-total-price";
export const selectCarAPI = "select-car";
export const selectCardDetailAPI = "select-card-details";
export const confirmBookingAPI = "confirm-booking";
export const getNotificationsAPI = "get-notification";

// Location
export const getLocationByAddressAPI = "search-location";
export const getLatLongAPI = "get-lat-long";

// SQUARE
export const squarePaymentAPI = "payment";
export const squareRegisterAPI = "square-create-customer";
